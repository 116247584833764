import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Contactus from '../components/contact'

const ContactUsPage = ({ location }) => {
  const lang = "en";
  return (
    <Layout lang={lang}>
      <SEO title="Contact Us" />
      <Contactus lang={lang} location={location}/>
    </Layout>
  )
}

export default ContactUsPage
